<template>
  <section id="flat_rate_method">
    <v-app>
      <v-row>
        <h1>Self Pickup</h1>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="8" class="ml-5">
                  <v-col cols="12">
                    <h4 class="card-title">Order pickup label at checkout</h4>
                    <p>Enter the name under which customers see this shipping method at checkout.</p>
                    <v-text-field v-model="method.title" outlined dense label="Self Pickup Label"></v-text-field>
                    <span class="text-danger" v-if="$v.method.title.$error">
                      <strong>Title is required</strong>
                    </span>
                  </v-col>

                  <v-col cols="12">
                    <h4 class="card-title">Detailed instructions for customers</h4>
                    <v-textarea outlined dense v-model="method.description"></v-textarea>
                    <p
                      class="text-muted"
                    >Describe in details how, where, and when your customers will be able to pick up their order. They will see this information at checkout and in order notification emails.</p>
                  </v-col>

                  <v-col cols="12">
                    <h4 class="card-title">Pickup charge</h4>
                    <p>Add an extra charge for pickup if delivering an order with this pickup option causes additional expenses for you. The amount specified below will be added to the order total at checkout.</p>
                    <v-text-field
                      outlined
                      v-model="method.prices[0].price"
                      :prefix="currency_symbol"
                      dense
                      type="number"
                      label="0.00"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <h4 class="card-title">Curbside or drive-through pickup</h4>
                    <p>
                      If you offer curbside or drive-through pickup instead of regular in-store pickup,
                      change the pickup label displayed at checkout. And be sure to update the pickup instructions
                      for customers to make it clear how that works exactly. For example: “When you’re at
                      the pickup location, call us at [insert your phone number here], and we’ll safely place
                      your order in your car’s trunk.”
                    </p>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <v-btn outlined dense :loading="loading" @click="createOrUpdate">Save</v-btn>
                  </v-col>
                </v-col>
                <v-col cols="4">
                  <!-- <pre>{{method}}</pre> -->
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-app>
  </section>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import EcommerceShippingMethodService from "@/services/store/shipping/method/EcommerceShippingMethodService";

const methodService = new EcommerceShippingMethodService();

export default {
  validations: {
    method: {
      title: { required }
    }
  },
  data() {
    return {
      methodId: null,
      domainname: null,
      loading: false,
      method: {
        type: null,
        title: null,
        is_active: 1,
        prices: [{ price: 0.0 }]
      }
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    currency_symbol() {
      return this.currentUser.store_config.currency_symbol;
    }
  },
  mounted() {
    this.domainname = this.$route.params.domainname;
    this.method.type = "pickup";
    if (
      this.$route.params.id != null &&
      this.$route.params.id != undefined &&
      this.$route.params.id != ""
    ) {
      this.methodId = this.$route.params.id;
      this.getMethod(this.methodId);
    }
  },
  methods: {
    getMethod(id) {
      methodService.show(this.domainname, id).then(response => {
        this.method = response.data.method;
        this.edit = true;
      // console.log(this.method);
      });
    },
    createOrUpdate() {
      this.loading = true;
      this.$v.$touch();
      if (this.$v.method.$error) {
        setTimeout(() => {
          this.$v.method.$reset();
          this.loading = false;
        }, 3000);
      } else {
        if (this.edit) {
          this.update();
        } else {
          this.save();
        }
      }
    },
    save() {
      methodService
        .create(this.domainname, this.method)
        .then(response => {
          this.reset();
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
       methodService
        .update(this.domainname, this.method.id, this.method)
        .then(response => {
          this.reset();
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.$v.method.$reset();
      this.$snotify.success("Updated Successfully");
      this.$router.push({ name: "shipping" });
    }
  }
};
</script>
<style scoped>
#flat_rate_method .card-title {
  font-size: 25px !important;
  font-weight: 600 !important;
}
#flat_rate_method p {
  font-size: 14px !important;
  font-weight: 500 !important;
}

#flat_rate_method .radio-item {
  font-size: 16px !important;
  font-weight: 500 !important;
}
</style>
